<template>
  <div>
    <h1>vb_paid_invoice</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>